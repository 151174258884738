import { baseUrl } from "../utils/http";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

export type ProgramGroup = {
  id: number;
  name: string;
  isActive: boolean;
  isVisibleToStandard: boolean;
  isVisibleToPremium: boolean;
  sortOrder?: number;
  programs?: ProgramGroupProgram[];
};

type GetProgramGroupsProps = {
  token: string;
};

type UpsertProgramGroupProps = {
  token: string;
  programGroup: ProgramGroup;
  id?: string;
};

const getProgramGroups = async ({
  token,
}: GetProgramGroupsProps): Promise<ProgramGroup[]> => {
  const response = await fetch(`${baseUrl}/program_groups`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (!(response.status === 200)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

type GetProgramGroupProps = {
  token: string;
  id: string;
};
const getProgramGroup = async ({ token, id }: GetProgramGroupProps) => {
  const response = await fetch(`${baseUrl}/program_groups/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (!(response.status === 200)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

const upsertProgramGroup = async ({
  token,
  programGroup,
  id,
}: UpsertProgramGroupProps) => {
  var method = id ? "PUT" : "POST";
  var url = id
    ? `${baseUrl}/program_groups/${id}`
    : `${baseUrl}/program_groups`;
  if (id) {
    programGroup.id = Number(id);
  }
  if (programGroup.sortOrder) {
    programGroup.sortOrder = Number(programGroup.sortOrder);
  }
  var body = JSON.stringify(programGroup);
  const response = await fetch(url, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: body,
  });
  if (!(response.status === 200 || response.status === 201)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

const useProgramGroups = () => {
  const auth0 = useAuth0();
  return useQuery(["programGroups", "all"], async () => {
    const token = (await auth0.getAccessTokenSilently()) as string;
    return getProgramGroups({ token });
  });
};

export const useProgramGroup = (id: string) => {
  const auth0 = useAuth0();
  return useQuery(["programGroup", id], async () => {
    const token = (await auth0.getAccessTokenSilently()) as string;
    return getProgramGroup({ token, id });
  });
};

type GetProgramsProps = {
  token: string;
};

export type Program = {
  id: number | null;
  name: string;
  subheader: string;
  isActive: boolean;
  isVisibleToStandard: boolean;
  isVisibleToPremium: boolean;
  createdAt: Date;
  updatedAt: Date;
  group: ProgramGroup;
  imageUrl: string;
  description: string;
  introVideoUrl: string;
  outroVideoUrl: string;
  sortOrder: number;
  introPlayerSpec: any;
  outroPlayerSpec: any;
  content: Content[];
  image?: FileList;
  minAppVersion: string;
};

export type ProgramGroupProgram = {
  id: number;
  name: string;
  isActive: boolean;
  isVisibleToStandard: boolean;
  isVisibleToPremium: boolean;
  imageUrl: string;
  sortOrder: number;
};

type UpsertProgramProps = {
  token: string;
  id?: string;
  program: Program;
};

const getPrograms = async ({ token }: GetProgramsProps) => {
  const response = await fetch(`${baseUrl}/programs`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (!(response.status === 200)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

const usePrograms = () => {
  const auth0 = useAuth0();
  return useQuery(["programs", "all"], async () => {
    const token = (await auth0.getAccessTokenSilently()) as string;
    return getPrograms({ token });
  });
};

export const useProgram = (id: string) => {
  const auth0 = useAuth0();
  return useQuery(["program", id], async () => {
    const token = (await auth0.getAccessTokenSilently()) as string;
    return getProgram({ token, id });
  });
};

type GetProgramProps = {
  token: string;
  id: string;
};
const getProgram = async ({ token, id }: GetProgramProps) => {
  const response = await fetch(`${baseUrl}/programs/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (!(response.status === 200)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

type Content = any;

const UpsertProgram = async ({ token, id, program }: UpsertProgramProps) => {
  const formData = new FormData();
  if (id !== undefined) {
    formData.append("id", id.toString());
  }
  formData.append("name", program.name);
  formData.append("isActive", program.isActive.toString());
  formData.append(
    "isVisibleToStandard",
    program.isVisibleToStandard.toString()
  );
  formData.append("isVisibleToPremium", program.isVisibleToPremium.toString());
  formData.append("groupId", program.group.id.toString());
  formData.append("introVideoUrl", program.introVideoUrl);
  if (program.description) {
    formData.append("description", program.description);
  }
  if (program.subheader) {
    formData.append("subheader", program.subheader);
  }
  if (program.outroVideoUrl) {
    formData.append("outroVideoUrl", program.outroVideoUrl);
  }
  if (program.sortOrder) {
    formData.append("sortOrder", program.sortOrder.toString());
  }
  if (program.introPlayerSpec) {
    formData.append("introPlayerSpec", program.introPlayerSpec);
  }
  if (program.outroPlayerSpec) {
    formData.append("outroPlayerSpec", program.outroPlayerSpec);
  }
  if (program.image) {
    formData.append("image", program.image[0]);
  }
  if (program.sortOrder) {
    formData.append("sort_order", program.sortOrder.toString());
  }
  if (program.imageUrl) {
    formData.append("imageUrl", program.imageUrl);
  }
  if (program.minAppVersion) {
    formData.append("minAppVersion", program.minAppVersion);
  }
  if (program.content) {
    formData.append(
      "content",
      JSON.stringify(
        program.content.map((c) => {
          return { id: c.id, week: Number(c.week) };
        })
      )
    );
  }
  const response = await fetch(`${baseUrl}/programs`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

export {
  getProgramGroups,
  useProgramGroups,
  upsertProgramGroup,
  getPrograms,
  usePrograms,
  UpsertProgram,
};
