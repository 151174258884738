import { Container, Typography } from "@mui/material";
import { ProgramForm } from "./program-form";
import * as React from "react";
import * as programsApi from "../../api/programs-api";
import { useParams, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Program } from "../../api/programs-api";

export const EditProgram = () => {
  const { id } = useParams<{ id: string }>();
  const getAccessTokenSilently = useAuth0().getAccessTokenSilently;
  const programQuery = programsApi.useProgram(id);
  const programsQuery = programsApi.usePrograms();
  const history = useHistory();
  if (programQuery.isLoading) {
    return <div>loading...</div>;
  }
  const program = programQuery.data;
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Edit Program
      </Typography>
      <div>
        <img src={program.imageUrl} alt={""} />
      </div>
      <ProgramForm
        imagesRequired={false}
        onSubmit={async (program: Program) => {
          const token = await getAccessTokenSilently();
          await programsApi.UpsertProgram({
            token,
            id,
            program,
          });
          programQuery.refetch();
          programsQuery.refetch();
          history.push("/programs");
        }}
        defaultValues={{
          id: program.id,
          name: program.name ? program.name : "",
          subheader: program.subheader ? program.subheader : "",
          isActive: program.isActive ? program.isActive : false,
          isVisibleToStandard: program.isVisibleToStandard
            ? program.isVisibleToStandard
            : false,
          isVisibleToPremium: program.isVisibleToPremium
            ? program.isVisibleToPremium
            : false,
          groupId: program.groupId ? program.groupId : "",
          introVideoUrl: program.introVideoUrl ? program.introVideoUrl : "",
          outroVideoUrl: program.outroVideoUrl ? program.outroVideoUrl : "",
          description: program.description !== null ? program.description : "",
          content: program.content !== null ? program.content : [],
          imageUrl: program.imageUrl !== null ? program.imageUrl : "",
          introPlayerSpec: program.introPlayerSpec
            ? JSON.stringify(program.introPlayerSpec)
            : "",
          outroPlayerSpec: program.outroPlayerSpec
            ? JSON.stringify(program.outroPlayerSpec)
            : "",
          sortOrder: program.sortOrder ? program.sortOrder : null,
          group: program.group ? program.group : "",
          minAppVersion: program.minAppVersion ? program.minAppVersion : "",
        }}
      />
    </Container>
  );
};
