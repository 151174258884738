import { Container, Typography } from "@mui/material";
import { ProgramForm } from "./program-form";
import * as React from "react";
import * as programsApi from "../../api/programs-api";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Program } from "../../api/programs-api";

export const CreateProgram = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const id = undefined;
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Create New Program
      </Typography>
      <ProgramForm
        imagesRequired={true}
        onSubmit={async (program: Program) => {
          const token = await getAccessTokenSilently();
          await programsApi.UpsertProgram({
            token,
            id,
            program,
          });
          history.push("/programs");
        }}
        defaultValues={{
          name: "",
          subheader: "",
          isActive: false,
          isVisibleToStandard: false,
          isVisibleToPremium: false,
          group: [],
          introVideoUrl: "",
          content: [],
          introPlayerSpec: null,
          outroPlayerSpec: null,
          outroVideoUrl: "",
          description: "",
          sortOrder: null,
          minAppVersion: "",
        }}
      />
    </Container>
  );
};
