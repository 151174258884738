import { useAuth0 } from "@auth0/auth0-react";
import Container from "@mui/material/Container";
import React from "react";
import { Loading, SnackbarMessage } from "../components";
import { baseUrl } from "../utils/http";
import TextField from "@mui/material/TextField";
import { useForm, useWatch } from "react-hook-form";
import { useQuery, useQueryCache } from "react-query";

import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import RenderJSON from "../components/render-json";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "33ch",
    },
  },
  fullField: {
    "&": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  button: {
    "&": {
      margin: theme.spacing(1),
    },
  },
}));

const updateSystemConfig = async ({
  appVersionAndroid,
  appVersionIos,
  appVersionOculus,
  firmwareVersion,
  goFirmwareVersion,
  newsBlurb,
  newsImageUrl,
  featureFlags,
  dates,
  token,
}) => {
  let body = {
    appVersionAndroid: appVersionAndroid,
    appVersionIos: appVersionIos,
    appVersionOculus: appVersionOculus,
    firmwareVersion: firmwareVersion,
    goFirmwareVersion: goFirmwareVersion,
    newsBlurb: newsBlurb,
    newsImageUrl: newsImageUrl,
    featureFlags: JSON.parse(featureFlags),
    dates: JSON.parse(dates),
  };
  const response = await fetch(`${baseUrl}/config`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  return await response.json();
};
export const System = () => {
  const [successOpen, setSuccessOpen] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  const [invalidFeatureFlags, setInvalidFeatureFlags] = React.useState(false);
  const [invalidDates, setInvalidDates] = React.useState(false);

  const { isLoading, isError } = useQuery("systemConfig", async () => {
    const token = await getAccessTokenSilently({
      audience: `https://api.liteboxer.com`,
    });
    const response = await fetch(`${baseUrl}/../config`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await response.json();

    if (!systemConfig) {
      reset({
        appVersionAndroid: json.config.appVersionAndroid,
        appVersionIos: json.config.appVersionIos,
        appVersionOculus: json.config.appVersionOculus,
        firmwareVersion: json.config.firmwareVersion,
        goFirmwareVersion: json.config.goFirmwareVersion,
        newsBlurb: json.config.newsBlurb,
        newsImageUrl: json.config.newsImageUrl,
        featureFlags: JSON.stringify(json.config.featureFlags),
        dates: JSON.stringify(json.config.dates),
      });
    }

    return json;
  });
  const queryCache = useQueryCache();
  const systemConfig = queryCache.getQueryData("systemConfig");
  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: {
      appVersionAndroid: systemConfig?.config?.appVersionAndroid ?? "",
      appVersionIos: systemConfig?.config?.appVersionIos ?? "",
      appVersionOculus: systemConfig?.config?.appVersionOculus ?? "",
      firmwareVersion: systemConfig?.config?.firmwareVersion ?? "",
      goFirmwareVersion: systemConfig?.config?.goFirmwareVersion ?? "",
      newsBlurb: systemConfig?.config?.newsBlurb ?? "",
      newsImageUrl: systemConfig?.config?.newsImageUrl ?? "",
      featureFlags: systemConfig?.config?.featureFlags
        ? JSON.stringify(systemConfig.config.featureFlags)
        : "",
      dates: systemConfig?.config?.dates
        ? JSON.stringify(systemConfig.config.dates)
        : "",
    },
  });
  const featureFlagsWatch = useWatch({
    control,
    name: "featureFlags",
    defaultValue: systemConfig?.config?.featureFlags
      ? JSON.stringify(systemConfig?.config?.featureFlags)
      : "",
  });
  const datesWatch = useWatch({
    control,
    name: "dates",
    defaultValue: systemConfig?.config?.dates ?? "",
  });
  if (isLoading) {
    return <Loading />;
  } else if (isError) {
    return (
      <SnackbarMessage severity="error">An error has occurred</SnackbarMessage>
    );
  }

  return (
    <Container className="mb-5">
      <Typography variant="h1" style={{ marginBottom: "10px" }}>
        System
      </Typography>
      {successOpen && (
        <SnackbarMessage
          severity="success"
          open={successOpen}
          autoHideDuration={6000}
          onClose={() => {
            setSuccessOpen(false);
          }}
        >
          System configuration updated
        </SnackbarMessage>
      )}
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(async (values) => {
          const token = await getAccessTokenSilently({
            audience: `https://api.liteboxer.com`,
          });
          const response = await updateSystemConfig({ ...values, token });
          queryCache.setQueryData("systemConfig", {
            config: {
              appVersionAndroid: response.appVersionAndroid,
              appVersionIos: response.appVersionIos,
              appVersionOculus: response.appVersionOculus,
              firmwareVersion: response.firmwareVersion,
              goFirmwareVersion: response.goFirmwareVersion,
              newsBlurb: response.newsBlurb,
              newsImageUrl: response.newsImageUrl,
              featureFlags: response.featureFlags,
              dates: response.dates,
            },
          });
          setSuccessOpen(true);
        })}
      >
        <div className={classes.root}>
          <TextField
            id="appVersionAndroid"
            label="appVersionAndroid"
            variant="outlined"
            name={"appVersionAndroid"}
            inputRef={register}
          />
          <TextField
            id="appVersionIos"
            label="appVersionIos"
            variant="outlined"
            name={"appVersionIos"}
            inputRef={register}
          />
          <TextField
            id="appVersionOculus"
            label="appVersionOculus"
            variant="outlined"
            name={"appVersionOculus"}
            inputRef={register}
          />
          <TextField
            id="firmwareVersion"
            label="firmwareVersion"
            variant="outlined"
            name={"firmwareVersion"}
            inputRef={register}
          />
          <TextField
            id="goFirmwareVersion"
            label="goFirmwareVersion"
            variant="outlined"
            name={"goFirmwareVersion"}
            inputRef={register}
          />
        </div>
        <div className={classes.fullField}>
          <TextField
            id="newsBlurb"
            label="newsBlurb"
            variant="outlined"
            name={"newsBlurb"}
            inputRef={register}
            style={{ width: "100%" }}
          />
        </div>
        <div className={classes.fullField}>
          <TextField
            id="newsImageUrl"
            label="newsImageUrl"
            variant="outlined"
            name={"newsImageUrl"}
            style={{ width: "100%" }}
            inputRef={register}
          />
        </div>
        <div className={classes.fullField}>
          <TextField
            id="featureFlags"
            label="featureFlags"
            variant="outlined"
            name={"featureFlags"}
            inputRef={register}
            style={{ width: "100%" }}
          />
        </div>
        <RenderJSON
          input={featureFlagsWatch}
          setStateAction={setInvalidFeatureFlags}
        />
        {invalidFeatureFlags && (
          <div style={{ color: "red" }}>
            Invalid JSON Detected. Check Feature Flags.
          </div>
        )}
        <div className={classes.fullField}>
          <TextField
            id="dates"
            label="dates"
            variant="outlined"
            name={"dates"}
            inputRef={register}
            style={{ width: "100%" }}
          />
        </div>
        <RenderJSON input={datesWatch} setStateAction={setInvalidDates} />
        {invalidDates && (
          <div style={{ color: "red" }}>
            Invalid JSON Detected. Check Dates.
          </div>
        )}
        {!invalidDates && !validDates(datesWatch) && (
          <div style={{ color: "red" }}>
            Invalid date detected. <br></br>
            Dates must follow ISO 8601 format unless null. <br></br>
            Both startDate and endDate must be present for each item. <br></br>
            Example:{" "}
            {JSON.stringify({
              testDate: {
                endDate: "2024-05-26T10:00:00.000Z",
                startDate: null,
              },
            })}
          </div>
        )}
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type={"submit"}
            disabled={
              invalidFeatureFlags ||
              invalidDates ||
              (!invalidDates && !validDates(datesWatch))
            }
          >
            Save
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              reset();
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Container>
  );
};

const validDates = (dates) => {
  try {
    const parsedDates = JSON.parse(dates);
    if (!parsedDates) {
      return false;
    }
    for (const key in parsedDates) {
      if (!isValidISO8601DateTime(parsedDates[key].startDate)) {
        return false;
      }
      if (!isValidISO8601DateTime(parsedDates[key].endDate)) {
        return false;
      }
    }
    return true;
  } catch (e) {
    return false;
  }
};

const isValidISO8601DateTime = (dateString) => {
  if (typeof dateString !== "string" && dateString !== null) {
    return false;
  }
  if (dateString === null) {
    return true;
  }

  // Regular expression to match the ISO 8601 format
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  if (!regex.test(dateString)) {
    return false;
  }
  const date = new Date(dateString);
  if (!date.getTime()) {
    // .getTime() is NaN if the date is invalid
    return false;
  }
  // Ensure the string representation matches the input (round-trip check)
  if (date.toISOString() !== dateString) {
    return false;
  }
  return true;
};

export default System;
